/*
 * Private settings
 * These settings should be used in the other settings files
 */

// set black so it can be used in the settings map
/*
 * Set base black and white color so it can be used in the settings
 */
$_black: #000;
$_white: #fff;

$_grey: #cbcbcb;
$_grey-light: #e4dfea;

$_purple: #bdaadf;
