$icon-small-size: 16px;
$icon-medium-size: $icon-small-size * 2;
$icon-responsive-size: $icon-small-size * 1.5;

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.icon--small {
    width: $icon-small-size;
    height: $icon-small-size;
    margin-left: setting(spacing, inline);
}

.icon--responsive {
    width: $icon-responsive-size;
    height: $icon-responsive-size;

    margin-right: $b;
}

.icon--medium {
    width: $icon-medium-size;
    height: $icon-medium-size;

    margin-right: $b / 2;
}

.icon--medium-no-margin {
    margin-right: 0;
}

.icon--big {
    width: $b * 4;
    height: $b * 4;
}
