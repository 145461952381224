/*
    Disable some lint rules
    - declaration-no-important: we don't really want mutations on these utility
*/
/* stylelint-disable declaration-no-important */

.u-uppercase {
    text-transform: uppercase;
}

.u-vertical-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.u-vertical-align-baseline {
    vertical-align: baseline;
}

.u-text-center {
    text-align: center;
}

.u-text-right {
    text-align: right;
}

.u-text-left {
    text-align: left;
}

.u-relative {
    position: relative;
}

.u-text-breakall {
    word-break: break-all;
}

.u-sr-only {
    @include sr-only;
}

.u-margin-bottom-none {
    margin-bottom: 0;
}

.u-margin-left-flush {
    margin-left: 0;
}

.u-margin-bottom {
    margin-bottom: setting(spacing, element);
}

.u-margin-bottom-responsive {
    margin-bottom: setting(spacing, element);

    @include media-query(desk) {
        margin-bottom: 0;
    }
}

.u-margin-bottom-big {
    margin-bottom: setting(spacing, element-big);
}

.u-margin-bottom-narrow {
    margin-bottom: setting(spacing, element-narrow);
}

.u-margin-top-auto {
    margin-top: auto;
}

.u-margin-right {
    margin-right: setting(spacing, element);
}

.u-margin-left-narrow {
    margin-left: setting(spacing, element-narrow);
}

.u-flex-end {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @include media-query(desk) {
        flex-direction: row;
    }
}

.u-flex-column {
    display: flex;
    flex-direction: column;

    height: 100vh;
}

.u-flex-center {
    display: flex;
    align-items: center;
}

.u-flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.u-flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.u-flex-full-height {
    display: flex;
    flex-direction: column;

    height: 100vh;
}

.u-justify-between {
    justify-content: space-between;
}

.u-justify-center {
    display: flex;
    justify-content: center;
}

.u-padding-top-narrow {
    padding-top: setting(spacing, element-narrow);
}

.u-padding-left {
    padding-left: ($inuit-layout-gutter / 2);
}

.u-padding {
    padding: setting(spacing, element);
}

.u-full-width {
    width: 100%;
}

.u-no-hover-underline:hover {
    text-decoration: none;
}

.u-separator-line {
    border-bottom: setting(border, separator);
    margin-bottom: setting(spacing, element);
}

.u-td-single-line {
    width: 1%;
}

.u-font-normal {
    font-weight: setting(font-weight, normal);
    font-size: setting(font-size, h3);
}

.u-font-price {
    color: setting(color, purple);
    font-weight: setting(font-weight, bold);
}

.u-font-tiny {
    font-size: setting(font-size, tiny);
}

.u-cursor-pointer {
    cursor: pointer;
}

.u-color-black {
    color: setting(color, black);
}

// hidden from sighted users but not from AT
.u-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
}

// set display to none for elements with the hidden attribute (this is for older browsers; < ie11)
[hidden] {
    display: none !important;
}

/* stylelint-enable declaration-no-important */
