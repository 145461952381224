.fields {
    border: 0;
    margin-bottom: setting(spacing, element-narrow);
}

.field__label--sr-only {
    @include sr-only;
}

.field__input,
.field__message {
    width: 100%;
    margin-bottom: setting(spacing, element-narrow);

    &--flush {
        margin-bottom: 0;
    }
}

.field__input::placeholder {
    color: setting(color, grey-darkest);
}

/* Varations */
.field--inline {
    .field__label,
    .field__input {
        vertical-align: middle;
        display: inline-block;
        width: auto;
        margin-bottom: setting(spacing, element-narrow);
    }
}

.field--row {
    width: 100%;

    .field__label,
    .field__input {
        display: block;
        float: left;
        margin-bottom: setting(spacing, element-narrow);
    }

    .field__input {
        float: right;
    }
}
