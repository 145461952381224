.btn--primary {
    background-color: setting(color, primary);
    border: solid 1px setting(color, primary);

    &:hover,
    &:focus,
    &:active,
    &:disabled {
        background-color: setting(color, grey-darker);
        border: solid 1px setting(color, grey-darker);
    }
}

.btn--ghost {
    background-color: transparent;
    border: solid 1px setting(color, primary);
    color: setting(color, primary);

    &:hover,
    &:focus,
    &:active {
        background-color: setting(color, purple);
        border: solid 1px setting(color, purple);
    }
}

.btn--link {
    background: transparent;
    line-height: $b;
    padding: 0;

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
    }

    &:disabled {
        text-decoration: none;
    }
}

.btn--thin {
    padding: 0 setting(spacing, inline);
}
