.alpha {
    @include alpha;
    @include font(heading);
}

.alpha--purple {
    color: setting(color, purple);
}

.beta {
    @include beta;
}

.gamma {
    @include gamma;
}

.gamma--footer {
    font-size: setting(font-size, footer-heading);
    font-weight: setting(font-weight, normal);
}

.gamma--category-title {
    @include font(default);

    font-size: setting(font-size, category);
    color: setting(color, purple);
    margin-bottom: setting(spacing, element-narrow);
}
