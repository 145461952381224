/*
 * Color settings
 */
$settings-color: (

    color: (
        primary: #bdaadf,

        grey-darkest: #545d62,
        grey-darker: #7f868a,
        grey: #a8a8a8,
        grey-lighter: #cbcbcb,
        grey-lightest: #ececec,
        grey-header: #585858,
        grey-body: #b6b6b7,

        cart: rgba(31, 31, 31, 0.6),

        add-product-purple: #e4dfea,

        purple: #bdaadf,
        purple-lighter: #f7f4fb,
        purple-lighter-border: #e4dfea,
        purple-light: #c5b4e3,
        purple-link: #5c6dad,

        pink: #fff4f8,

        white: $_white,
        black: $_black,

        link: #3da4d7,
        link-highlight: #000
    ),

    state: (
        error-color: #de596d,
        error-border-color: #de596d,

        danger-color: #a94442,
        danger-border-color: #ebccd1,
        danger-background-color: #f2dede,

        success-color: #59de70,
        success-border-color: #59de70,
        success-background-color: #dff0d8,

        warning-color: #8a6d3b,
        warning-border-color: #faebcc,
        warning-background-color: #fcf8e3,
    )

);
