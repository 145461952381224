$block-height: 260px;

.document-block {
    color: setting(color, purple);
    height: $block-height;
    word-break: normal;

    display: flex;
    justify-content: center;
    align-items: flex-end;

    &:hover {
        text-decoration: underline;
    }
}
