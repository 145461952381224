.product-add-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: sticky;
    top: 0;
    right: 0;

    background-color: setting(color, white);
    padding: setting(spacing, element-narrow) 0;
    border-bottom: setting(border, products);
    margin-bottom: setting(spacing, element);
}

.product-add-bar__message {
    margin-right: setting(spacing, element);
    padding: setting(spacing, inline-small);

    border-radius: setting(border-radius, default);
    background-color: setting(color, add-product-purple);

    a {
        color: setting(color, purple-link);
        text-decoration: underline;
    }
}

.product-add-bar__icon {
    margin-left: setting(spacing, element-narrow);
    margin-right: 0;
}
