$logo-height: 66px;
$logo-height-responsive: $logo-height / 2;

.logo {
    display: block;
    height: $logo-height;
}

.logo--responsive {
    height: $logo-height-responsive;
    padding-left: setting(spacing, element);
}
