// Important is needed to overwrite CSS of a external package
/* stylelint-disable declaration-property-value-disallowed-list, declaration-no-important, selector-max-specificity, selector-max-compound-selectors*/
.v-select {
    .vs__actions {
        display: none !important;
    }

    .vs__selected-options,
    .dropdown-toggle {
        padding: 0 !important;
        border: none !important;
    }

    .selected-tag {
        background: setting(color, white) !important;
        margin: 0 !important;
        border-radius: unset !important;
        border: setting(border, search) !important;
        border-right: 0 !important;
    }

    input {
        margin-top: 0 !important;
        border-left: 0 !important;
    }

    .dropdown-menu {
        display: none !important;
        border: setting(border, input) !important;
        border-radius: 0 !important;
    }

    .dropdown-menu li a:hover {
        background-color: setting(color, purple) !important;
        color: setting(color, white) !important;
        text-decoration: none;
    }

    .dropdown-menu .active > a,
    .dropdown-menu > .highlight > a {
        background-color: setting(color, purple) !important;
    }

    &.searching .dropdown-menu {
        display: block !important;
    }
}
