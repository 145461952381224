$flyer-height: 375px;

.grid {
    display: flex;
    flex-wrap: wrap;

    @include media-query(desk) {
        align-items: stretch;
        justify-content: flex-start;
    }
}

.grid--center {
    @include media-query(desk) {
        justify-content: center;
    }
}

.grid__item,
.grid__item--new-developments {
    width: 100%;
    margin-bottom: setting(spacing, element);

    @include media-query(desk) {
        margin-left: $inuit-layout-gutter--small / 2;
        margin-right: $inuit-layout-gutter--small / 2;
        width: calc(#{percentage(1/3)} - #{$inuit-layout-gutter--small});
    }
}

.grid__item--new-developments {
    height: $flyer-height;

    @include media-query(desk) {
        margin-left: $inuit-layout-gutter--small;
        margin-right: $inuit-layout-gutter--small;
        width: calc(#{percentage(1/4)} - (#{$inuit-layout-gutter--small} * 2));
    }

    &:hover .new-developments__overlay {
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.grid__item--category,
.grid__item--document {
    width: calc(50% - #{setting(spacing, element)});

    margin-left: setting(spacing, element-narrow);
    margin-right: setting(spacing, element-narrow);
    margin-bottom: setting(spacing, element);

    @include media-query(desk) {
        margin-left: setting(spacing, element-narrow);
        margin-right: setting(spacing, element-narrow);
        margin-bottom: 0;
    }
}

.grid__item--category {
    @include media-query(desk) {
        width: calc(#{percentage(1/5)} - #{setting(spacing, element)});
    }
}

.grid__item--document {
    @include media-query(desk) {
        width: calc(#{percentage(1/4)} - #{setting(spacing, element)});
    }
}

.grid__item--sub-category {
    @include media-query(desk) {
        width: calc(#{percentage(1/3)} - #{$inuit-layout-gutter--small});
    }
}
