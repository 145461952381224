.category-menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 100%;
    margin-right: 0;
    margin-bottom: setting(spacing, element-narrow);

    @include media-query(desk) {
        flex-wrap: nowrap;
        justify-content: flex-end;

        width: 50%;
        margin-bottom: 0;
    }
}

.category-menu__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100%;
    color: setting(color, black);
    margin-left: 0;
    margin-bottom: setting(spacing, inline);

    @include media-query(desk) {
        margin-left: setting(spacing, element);
        margin-bottom: 0;
    }

    &:hover {
        text-decoration: underline;
    }
}

.category-menu__item-image {
    @include aspect-ratio-from-height(260, 160, ($b));

    margin-bottom: 0;

    @include media-query(desk) {
        @include aspect-ratio-from-height(260, 160, ($b * 2));

        margin-bottom: setting(spacing, inline);
    }
}

.category-menu__item-title {
    font-size: setting(font-size, tiny);
}
