a,
.link-auth,
.link-purple {
    color: setting(color, white);
    text-decoration: none;
    word-break: break-all;

    @include media-query(desk) {
        word-break: normal;
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
    }

    > object,
    > svg {
        pointer-events: none;
    }
}

.link-auth,
.link-purple {
    color: setting(color, purple);
}
