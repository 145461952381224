/*
 * Spacing settings
 */
$settings-spacing: (

    spacing: (
        element: $b,
        element-small: ($b / 8),
        element-narrow: ($b / 2),
        element-big: ($b * 2.5),
        section-small: ($b * 1.5),
        section: ($b * 2),
        section-narrow: $b,
        inline: ($b / 4),
        inline-small: ($b / 2.5)
    )

);
