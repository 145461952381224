/*
 * Font settings
 */
$settings-font: (

    font-family: (
        default: 'Open Sans',
        fallback: sans-serif,
        headings: 'Raleway',
        headings-fallback: sans-serif,
    ),

    font-size: (
        default: $inuit-base-font-size,
        lede: 17px,
        smaller: 16px,
        tiny: 13px,

        h1: 23px,
        h2: 21px,
        h3: 18px,

        footer-heading: 22px,
        category: 22px,
        footer: 14px,
        table: 13px,
        wizard: 13px,
        wizard-heading: 20px,
        input: 19px,
        tooltip: 12px,
    ),

    font-size-lap-and-up: (
        h1: 40px,
        h2: 26px,
        h3: 18px,
    ),

    font-weight: (
        super-light: 100,
        light: 300,
        normal: 400,
        bold: 700
    ),

    letter-spacing: ()

);
