.alert {
    padding: setting(spacing, element-narrow);
    border: setting(border, alert);

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.alert--success {
    background-color: setting(state, success-background-color);
    border-color: setting(state, success-border-color);
    color: setting(state, success-color);
}

.alert--danger {
    background-color: setting(state, danger-background-color);
    border-color: setting(state, danger-border-color);
    color: setting(state, danger-color);
}

.alert--warning {
    background-color: setting(state, warning-background-color);
    border-color: setting(state, warning-border-color);
    color: setting(state, warning-color);
}

.alert-position {
    z-index: 2;
    width: 100%;

    position: fixed;

    top: percentage(2/10);
    left: 50%;
    transform: translate(-50%, -50%);

    padding-left: setting(spacing, section);
    padding-right: setting(spacing, section);

    @include media-query(desk) {
        width: 50%;
    }
}

.alert-close {
    background-color: transparent;
    border: 0;
    color: #a94442;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}
