html {
    @include font;

    overflow-y: scroll;
    overflow-x: hidden;

    min-height: 100%;

    text-size-adjust: 100%;

    height: 100%;
}

body {
    font-weight: setting(font-weight, normal);
    color: setting(color, black);
    text-size-adjust: 100%;
    line-height: 1.7;
    background-color: setting(color, grey-body);
}

p {
    margin-bottom: setting(spacing, element);
}

.p--flush {
    margin-bottom: 0;
}

.small {
    @include inuit-font-size(setting(font-size, smaller));
}

.page-background-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;

    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        background-color: setting(color, grey-body);
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.75;
    }
}
