/*
 * Merge all settings
 */
// stylelint-disable function-parentheses-space-inside
$settings: map-collect(
    $settings-color,
    $settings-font,
    $settings-border,
    $settings-box-shadow,
    $settings-text-shadow,
    $settings-spacing,
    $settings-z-index,
    $settings-general
);
// stylelint-enable function-parentheses-space-inside
