//
// #SETTINGS
//
// Default settings:
// https://github.com/inuitcss/settings.defaults/blob/master/_settings.defaults.scss
//
$inuit-base-font-size: 18px;
$inuit-base-line-height: 30px;

@import 'inuit-defaults/settings.defaults';
$b: ($inuit-base-line-height / $inuit-base-font-size) * 1rem;

$inuit-responsive-settings: true;
@import 'inuit-responsive-settings/settings.responsive';

//
// #TOOLS
//
@import 'inuit-functions/tools.functions';
@import 'inuit-mixins/tools.mixins';
@import 'inuit-responsive-tools/tools.responsive';
@import 'inuit-tools-widths/tools.widths';

@import 'tools/functions';
@import 'tools/str-replace';
@import 'tools/svg';

//
// #SETTINGS
//
@import 'settings/private';
@import 'settings/colors';
@import 'settings/font';
@import 'settings/spacing';
@import 'settings/border';
@import 'settings/box-shadow';
@import 'settings/text-shadow';
@import 'settings/z-index';
@import 'settings/general';
@import 'settings/settings';

//
// #MIXINS
//
@import 'mixins/font';
@import 'mixins/headings';
@import 'mixins/utilities';
@import 'mixins/aspect-ratio';

//
// #GENERIC
//
@import 'normalize.css/normalize';
@import 'inuit-reset/generic.reset';
@import 'inuit-box-sizing/generic.box-sizing';

//
// #BASE
//
$inuit-base-background-color: #eee;
$inuit-base-text-color: setting(color, black);

@import 'inuit-page/base.page';
@import 'inuit-images/base.images';

@import 'generic/page';
@import 'generic/headings';
@import 'generic/links';
@import 'generic/list';
@import 'generic/table';

//
// #EXTERNAL
//

//
// #OBJECTS
//
@import 'inuit-layout/objects.layout';
@import 'inuit-buttons/objects.buttons';

$w2w-enable-section--filled: true;
@import 'objects/section';
@import 'objects/container';
@import 'objects/embed';
@import 'objects/content';
@import 'objects/actions';
@import 'objects/fields';
@import 'objects/cover';
@import 'objects/grid';
@import 'objects/button';

//
// #COMPONENTS
//
@import 'components/alerts';
@import 'components/button';
@import 'components/category-block';
@import 'components/document-block';
@import 'components/fields';
@import 'components/footer';
@import 'components/hamburger-menu';
@import 'components/icon';
@import 'components/login-page';
@import 'components/logo';
@import 'components/new-developments';
@import 'components/page-block';
@import 'components/page-header';
@import 'components/table';
@import 'components/tooltip';
@import 'components/v-select';
@import 'components/wizard';
@import 'components/tabs';
@import 'components/product-bar';
@import 'components/category-menu';
@import 'components/title-beam';

//
// #TRUMPS
//
@import 'inuit-widths/trumps.widths';
@import 'inuit-print/trumps.print';

$inuit-widths-columns-responsive: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
@import 'inuit-responsive-widths/trumps.widths-responsive';

@import 'inuit-spacing/trumps.spacing';
@import 'inuit-clearfix/trumps.clearfix';
@import 'inuit-responsive-spacing/trumps.spacing-responsive';

@import 'utilities/utilities';
@import 'utilities/position';
@import 'utilities/display';
