/*
 * Border settings
 */
$settings-text-shadow: (

    text-shadow: (
        default: 1px 1px 1px #252525
    ),

);
