@mixin font($type: default) {
    @if $type == default {

        font-family: 'Open Sans';
        font-size: setting(font-size, smaller);

    } @else if $type == heading {

        font-family: Raleway, sans-serif;
        font-weight: setting(font-weight, normal);

    } @else {

        @warn 'D’oh! ‘#{$type}’ is not a valid value for the font stack.';

    }
}
