$field-height: 40px;
$field-height-bigger: 46px;
$field-height-smaller: 28px;
$field-height-search: 60px;
$search-icon-size: $b;
$search-icon-position-right: 16px;
$search-icon-position-top: 17px;

.field-search::after {
    content: '';
    position: absolute;
    display: block;
    right: $search-icon-position-right;
    top: $search-icon-position-top;
    width: $search-icon-size;
    height: $search-icon-size;
    background-image: url('/dist/front/images/icomoon/SVG/icon-search-gray.svg');
    background-size: 100%;
}

.field__input {
    border: setting(border, input);
    height: $field-height;
    padding: setting(spacing, inline) setting(spacing, element-narrow);
}

.field__input--filter {
    height: $field-height-bigger;
    width: percentage(3/10);
}

.field__input--smaller {
    height: $field-height-smaller;
    margin-bottom: 0;
}

/* stylelint-disable declaration-no-important */
.field__input-search {
    // Important is needed to overwrite CSS of a external package
    input {
        border: setting(border, search) !important;
        padding: setting(spacing, inline) setting(spacing, element-narrow) !important;
        font-size: setting(font-size, input) !important;
        margin-bottom: 0 !important;
        height: $field-height-search;
        background-color: setting(color, white) !important;
    }

    input:focus {
        outline: none;
    }

    input::placeholder {
        color: setting(color, purple);
        opacity: 1;
        font-weight: setting(font-weight, super-light);
    }
}

.field__input--select {
    background-color: setting(color, white);
}

.field__input--file {
    border: 0;
    height: 100%;
    padding-left: 0;
}

.field__input--static {
    border: 0;
    padding-left: 0;
}

.field__input--textarea {
    resize: vertical;
}

.field__input--no-padding {
    padding-left: 0;
}

.is-required .field__label::after {
    content: '*';
    color: setting(color, primary);
    padding-left: setting(spacing, inline);
    font-weight: setting(font-weight, bold);
}

.field__label {
    font-weight: setting(font-weight, bold);
}

.field--radio,
.field--check {
    margin-bottom: 0;

    .field__input {
        display: inline-block;
        width: auto;
        height: auto;
        margin-right: setting(spacing, inline);
    }
}

.field--select-button {
    display: flex;

    .field__input {
        margin-right: setting(spacing, inline);
    }

    .field__button {
        flex: 1 0 auto;
    }
}

.field__input--number {
    font-variant-numeric: tabular-nums;
}

.has-error input {
    border-color: setting(state, error-color);
}

.has-error .field__message {
    color: setting(state, error-color);
}
