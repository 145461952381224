$footer-width: 260px;
$footer-height: 74px;

.footer {
    background-color: setting(color, grey-header);
    padding: setting(spacing, element-narrow) 0;
    color: setting(color, white);
}

.footer__bottom-bar {
    background-color: setting(color, grey-body);
    color: setting(color, white);

    padding: setting(spacing, element) 0;
    text-align: center;
    text-transform: uppercase;
    font-size: setting(font-size, footer);
}

.footer__logo {
    margin-bottom: setting(spacing, element);
    width: $footer-width;
    height: $footer-height;
}

.footer__list-link {
    color: setting(color, purple);
}
