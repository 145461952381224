/*
 * Z-index settings
 */
$settings-z-index: (

    z-index: (
        page-header: 1,
    )

);
