.btn {
    word-break: break-word;
    padding: setting(spacing, element-narrow) setting(spacing, element);
    border: 0;

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        outline: 0;
    }

    &:disabled {
        cursor: default;
    }
}

.btn--small-flex {
    display: inline-flex;
    align-items: center;

    padding: setting(spacing, inline) setting(spacing, element-narrow);
}

.btn--continue {
    margin-bottom: setting(spacing, element-narrow);
}

.btn--full-width-responsive {
    width: 100%;

    @include media-query(desk) {
        width: auto;
    }
}
