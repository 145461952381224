$subcategory-block-height: 350px;
$subcategory-image-small: 150px;

.page-block {
    padding: setting(spacing, element);
    background-color: setting(color, white);
}

.page-block--wizard {
    padding: 0;
}

.page-block__link {
    color: setting(color, black);

    &:hover {
        text-decoration: underline;
    }
}

// Links from Wysiwyg editor
.page-block-wysiwyg a {
    color: setting(color, purple);
}

.page-block__breadcrumbs {
    margin-bottom: setting(spacing, element-narrow);

    @include media-query(desk) {
        margin-bottom: 0;
    }
}

.page-block__breadcrumb-link {
    color: setting(color, purple-light);

    &:hover {
        text-decoration: underline;
    }
}

.page-block__title {
    color: setting(color, purple-light);
}

.page-block__title-beam {
    background-color: setting(color, purple);
    color: setting(color, white);
    text-align: center;
    width: 100%;
    padding: setting(spacing, element-narrow) setting(spacing, element);
}

.page-block_subcategory,
.page-block_subcategory-image-small {
    background-color: setting(color, grey-lightest);
    color: setting(color, black);
    height: $subcategory-block-height;

    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.page-block_subcategory-image-small {
    height: $subcategory-image-small;
    width: $subcategory-image-small;
    margin-bottom: setting(spacing, element-narrow);

    @include media-query(desk) {
        margin-right: setting(spacing, element);
        margin-bottom: 0;
    }
}

//Has to be less magical in the future
.page-block_document-image {
    @include aspect-ratio-from-height(260, 160, ($b * 3));
    margin-bottom: setting(spacing, element);

    @include media-query(desk) {
        @include aspect-ratio-from-height(260, 160, ($b * 6));
    }
}

.page-block_category-image {
    @include aspect-ratio-from-height(260, 160, ($b * 2));
    margin-bottom: setting(spacing, element);

    @include media-query(desk) {
        @include aspect-ratio-from-height(260, 160, ($b * 4));
    }
}

.page-block__header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include media-query(desk) {
        flex-direction: row;
    }
}

.page-block__sub-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: setting(spacing, element);

    @include media-query(desk) {
        flex-direction: row;
    }
}
