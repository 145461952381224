$hamburger-item-width: 24px;
$hamburger-item-height: 2px;

.hamburger-menu {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding-right: setting(spacing, element);
    padding-top: setting(spacing, element-narrow);
    position: relative;
    user-select: none;
}

.hamburger-menu__item {
    background: setting(color, white);
    display: block;
    height: $hamburger-item-height;
    position: relative;
    transition: background 0.2s ease-out;
    width: $hamburger-item-width;
}

.hamburger-menu__item::before,
.hamburger-menu__item::after {
    content: '';
    background: setting(color, white);
    display: block;
    position: absolute;
    transition: all 0.2s ease-out;

    height: 100%;
    width: 100%;
}

.hamburger-menu__item::before {
    top: $hamburger-item-width / 3;
}

.hamburger-menu__item::after {
    top: -$hamburger-item-width / 3;
}

.hamburger-menu__btn {
    display: none;
}

// Check if hamburger-menu__btn is checked
// https://developer.mozilla.org/en-US/docs/Web/CSS/General_sibling_selectors

.hamburger-menu__btn:checked ~ .page-header__nav-responsive {
    max-height: 100%;
}

/* stylelint-disable selector-max-specificity */
.hamburger-menu__btn:checked ~ .hamburger-menu .hamburger-menu__item {
    background: transparent;
}

//Rotate hamburger menu items to create a cross.
.hamburger-menu__btn:checked ~ .hamburger-menu .hamburger-menu__item::before {
    transform: rotate(-45deg);
}

.hamburger-menu__btn:checked ~ .hamburger-menu .hamburger-menu__item::after {
    transform: rotate(45deg);
}

.hamburger-menu__btn:checked ~ .hamburger-menu .hamburger-menu__item::before,
.hamburger-menu__btn:checked ~ .hamburger-menu .hamburger-menu__item::after {
    top: 0;
}
/* stylelint-enable */
