/*
 * Border settings
 */
$settings-border: (
    border: (
        input: solid 1px $_grey-light,
        table: solid 1px $_grey-light,
        separator: solid 1px $_grey-light,
        search: solid 1px $_grey-light,
        purple: 1px solid $_purple,
        alert: 1px solid transparent,
        products: solid 1px $_grey-light
    ),

    border-radius: (
        default: 5px
    )
);
