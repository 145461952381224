$page-header-padding-bottom: setting(spacing, element-narrow);
$header-height: 220px;

.page-header {
    background-color: setting(color, grey-header);
    color: setting(color, white);

    padding-top: setting(spacing, element-narrow);
    padding-bottom: 0;

    @include media-query(desk) {
        height: $header-height;
        padding-top: setting(spacing, element);
    }
}

.page-header__nav {
    text-transform: uppercase;
}

.page-header__nav-responsive {
    overflow: hidden;
    background-color: setting(color, grey-header);
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
}

.page-header__nav-item.active {
    background-color: setting(color, purple-light);

    > a {
        color: setting(color, black);
    }
}

.page-header__nav-link-responsive,
.page-header__nav-link-icon {
    display: block;
    padding: setting(spacing, element-narrow);
    text-decoration: none;

    display: flex;
    align-items: center;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.page-header__nav-link-icon {
    padding-left: 0;
    padding-right: 0;

    &:hover {
        background-color: transparent;
    }
}

.page-header__nav-link {
    padding: setting(spacing, inline) setting(spacing, element) setting(spacing, element);
    display: block;
    text-decoration: none;

    &:hover,
    &:focus {
        background-color: setting(color, purple-light);
        color: setting(color, black);
        text-decoration: none;
    }
}

.page-header__top-nav {
    font-size: percentage(8/10);
    text-transform: uppercase;
}

.page-header__top-nav-link {
    display: flex;
    align-items: center;
}

.page-header__top-nav-item {
    &:not(:last-child) {
        padding-right: setting(spacing, element);
    }

    &:first-child {
        padding-right: setting(spacing, section-small);
    }
}

.page-header__top-nav-current {
    color: setting(color, purple-light);
}

.page-header__cart {
    background-color: setting(color, cart);
    padding: setting(spacing, element-small) setting(spacing, element-narrow);

    display: flex;
    align-items: center;
    justify-content: center;

    @include media-query(desk) {
        justify-content: flex-start;
    }
}

.page-header__cart-info {
    font-size: 80%;
}
