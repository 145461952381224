$product-image-height: $b * 2;

.table {
    thead {
        background-color: setting(color, grey-lightest);
    }

    td {
        font-size: setting(font-size, table);
        padding: setting(spacing, element-narrow);
    }
}

.table--product {
    td {
        padding: setting(spacing, inline);
    }
}

.table__product-image {
    transition: transform 0.2s;
    cursor: pointer;
    height: $product-image-height;

    &:hover {
        transform: scale(5);
    }
}
