.wizard__item {
    padding: setting(spacing, element-narrow);
    text-align: center;
    font-size: setting(font-size, wizard-heading);
    background-color: setting(color, black);
}

.wizard__item.is-active {
    background-color: setting(color, purple);

    > .wizard__title {
        color: setting(color, white);
    }
}

.wizard__remove {
    display: block;
    background-color: setting(color, purple);
    padding: setting(spacing, element-narrow);
    font-size: setting(font-size, h1);
    text-align: center;
}

.wizard__table-title {
    font-weight: setting(font-weight, normal);
    font-size: setting(font-size, h3);
}

.wizard__small-title {
    color: setting(color, grey-header);
    font-size: setting(font-size, wizard);
    font-weight: setting(font-weight, bold);
}

.wizard__table-total-price {
    color: setting(color, purple);
    font-weight: setting(font-weight, bold);
}

.wizard__help-text {
    padding: setting(spacing, element-narrow);
    background-color: setting(color, grey-lightest);
    text-align: center;
    font-weight: setting(font-weight, bold);
    margin-bottom: setting(spacing, element);

    a {
        color: setting(color, purple);
    }
}

.wizard__link {
    color: setting(color, purple);
}

.wizard__steps {
    display: flex;
    justify-content: space-between;
}

.wizard__steps--cart {
    flex-direction: column;

    @include media-query(desk) {
        flex-direction: row;
    }
}

.wizard__step {
    padding: 0 setting(spacing, element);
    font-size: setting(font-size, wizard);
}

.wizard__information {
    font-size: setting(font-size, wizard);
}

.wizard__notification {
    padding: setting(spacing, element);
    border: setting(border, purple);
    margin-bottom: setting(spacing, element);
    font-weight: setting(font-weight, bold);
}

.wizard__info {
    vertical-align: text-bottom;
}
