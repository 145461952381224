.category-block {
    height: 100%;
    color: setting(color, black);

    display: flex;
    align-items: center;
    flex-direction: column;

    &:hover {
        color: setting(color, white);
    }
}
