.table {
    width: 100%;
    margin-bottom: setting(spacing, element);

    thead {
        padding: setting(spacing, element-narrow);
        font-weight: setting(font-weight, bold);
    }

    td {
        border-bottom: setting(border, table);
    }
}

.table--responsive {
    @include media-query(portable) {
        display: block;
        width: 100%;
        overflow-x: auto;
    }
}
