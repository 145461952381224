.new-developments__content {
    height: 100%;
    box-shadow: setting(box-shadow, default);
    background-position: top center;
}

.new-developments__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    background: setting(color, purple);
    color: setting(color, white);
}
