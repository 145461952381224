.tabs {
    display: flex;
    flex-wrap: wrap;
}

.tabs__label {
    cursor: pointer;
    display: inline-block;
    width: 100%;
    padding: setting(spacing, element-narrow);
    font-weight: setting(font-weight, bold);
    color: setting(color, grey);
    margin-bottom: setting(spacing, element);

    @include media-query(desk) {
        width: auto;
    }

    &:hover {
        color: setting(color, purple);
    }

    &.router-link-exact-active {
        border-bottom: 3px solid setting(color, purple);
        color: setting(color, purple);
    }
}

.tabs__content {
    width: 100%;
    order: 1;
}
